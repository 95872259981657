import { RESET_STATE } from "./actions";

const initialState = { info: {}, data: [], count: 0, annotations: [[]] };

const data = (state = initialState, action) => {
  switch (action.type) {
    case "RECEIVE_JSON":
      let newState = {
        data: action.data.data,
        info: action.data.info,
        count: state.count,
      };
      newState.annotations =
        action.data.annotations.length === 0
          ? [[]]
          : action.data.annotations || [[]];
      newState.file_name = action.file_name;
      return Object.assign({}, newState);
    case "ADD_HEARTBEAT":
      const copyAnnotationsForAdd = state.annotations.slice();
      copyAnnotationsForAdd.push([]);
      return {
        ...state,
        annotations: copyAnnotationsForAdd,
        count: copyAnnotationsForAdd.length - 1,
      };
    case "ADD_ANNOTATIONS":
      const copyAnnotationsForMarkers = state.annotations.slice();
      if (
        Object.prototype.toString.call(
          copyAnnotationsForMarkers[state.count]
        ) === "[object Array]"
      ) {
        let findMarker = copyAnnotationsForMarkers[state.count].find(
          (_) => _.name === action.paramName
        );
        if (findMarker) {
          findMarker.data = action.data;
        } else {
          copyAnnotationsForMarkers[state.count].push({
            name: action.paramName,
            data: { ...action.data },
          });
        }
        return { ...state, annotations: copyAnnotationsForMarkers };
      }
      return state;
    case "REMOVE_HEARTBEAT":
      const copyAnnotationsForRemove = state.annotations.slice();
      copyAnnotationsForRemove.pop();
      return {
        ...state,
        annotations: copyAnnotationsForRemove,
        count: copyAnnotationsForRemove.length - 1,
      };
    case "NEXT_HEARTBEAT":
      if (state.count + 1 < state.annotations.length) {
        return Object.assign({}, { ...state, count: state.count + 1 });
      }
      return state;
    case "PREV_HEARTBEAT":
      if (state.count - 1 >= 0) {
        return Object.assign({}, { ...state, count: state.count - 1 });
      }
      return state;
    case "CHANGE_USER_INFO":
      const { field_name } = action;
      return { ...state, info: { ...state.info, [field_name]: action.value } };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default data;
