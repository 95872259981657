/**
 * @file auth.actions.js
 * @memberof Auth
 * @description Action creators for authentication-related actions in the Redux store.
 * This file contains actions for login, logout, registration, password recovery, and reset.
 */

import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from "../actions";

/**
 * Initiates user login process
 */
export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});

/**
 * Handles successful user login
 */
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

/**
 * Handles user login error
 */
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

/**
 * Initiates forgot password process
 */
export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history },
});

/**
 * Handles successful forgot password request
 */
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});

/**
 * Handles forgot password request error
 */
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

/**
 * Initiates password reset process
 */
export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode, newPassword, history },
});

/**
 * Handles successful password reset
 */
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword,
});

/**
 * Handles password reset error
 */
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

/**
 * Initiates user registration process
 */
export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});

/**
 * Handles successful user registration
 */
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

/**
 * Handles user registration error
 */
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message },
});

/**
 * Initiates user logout process
 */
export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});
