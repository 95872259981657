import {
  RECORDINGS_GET_LIST,
  RECORDINGS_GET_LIST_BY_ID,
  RECORDINGS_LIST_SUCCESS,
  RECORDINGS_GET_LIST_ERROR,
  RECORDINGS_GET_LIST_BY_ID_SUCCESS,
  RECORDINGS_GET_LIST_BY_ID_ERROR,
  RECORDINGS_GET_LIST_BY_USER_ID,
  RECORDINGS_GET_LIST_BY_USER_ID_SUCCESS,
  RECORDINGS_GET_LIST_BY_USER_ID_ERROR,
  RECORDINGS_GET_SEARCH_LIST_BY_TERM,
  RECORDINGS_GET_SEARCH_LIST_BY_TERM_SUCCESS,
  RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM,
  RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM_SUCCESS,
  RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM_ERROR,
  RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM,
  RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM_SUCCESS,
  RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM_ERROR,
  RECORDINGS_LIST_BY_ORDER,
  RECORDINGS_LIST_BY_ORDER_ERROR,
  RECORDINGS_LIST_BY_ORDER_SUCCESS,
  RECORDING_LIST_GET_LIST_WITH_FILTER,
  RECORDINGS_LIST_BY_ORDER_BY_CLIENT,
  RECORDINGS_LIST_BY_ORDER_BY_CLIENT_SUCCESS,
  RECORDINGS_LIST_BY_ORDER_BY_CLIENT_ERROR,
  RECORDINGS_LIST_BY_ORDER_BY_USER,
  RECORDINGS_LIST_BY_ORDER_BY_USER_SUCCESS,
  RECORDINGS_LIST_BY_ORDER_BY_USER_ERROR,
  RECORDINGS_GET_TAGS,
  RECORDINGS_GET_TAGS_SUCCESS,
  RECORDINGS_GET_TAGS_ERROR,
  RECEIVE_JSON,
  SELECT_PARAM,
  RECEIVE_FILES,
  REQUEST_FILES,
  ADD_ANNOTATIONS,
  REMOVE_PARAMS,
  NEXT_HEARTBEAT,
  PREV_HEARTBEAT,
  ADD_HEARTBEAT,
  REMOVE_HEARTBEAT,
  CHANGE_USER_INFO,
  SEND_REQUEST_FILE,
  RECORDINGS_GET_LIST_BY_DATES,
  RECORDINGS_GET_LIST_BY_DATES_SUCCESS,
  RECORDINGS_GET_LIST_BY_DATES_ERROR,
  RECORDINGS_GET_LIST_BY_DATES_COMPANY,
  RECORDINGS_GET_LIST_BY_DATES_COMPANY_SUCCESS,
  RECORDINGS_GET_LIST_BY_DATES_COMPANY_ERROR,
  RECORDINGS_GET_LIST_BY_DATES_USER,
  RECORDINGS_GET_LIST_BY_DATES_USER_SUCCESS,
  RECORDINGS_GET_LIST_BY_DATES_USER_ERROR,
  RECORDING_DELETE,
  RECORDING_PAGINATION,
  SET_LOADER,
  RECORDING_ALL_USERS,
} from "../actions";

const INIT_STATE = {
  allRecordings: [],
  recordingItems: [],
  error: "",
  filter: "",
  searchKeyword: "",
  orderColumn: null,
  loading: false,
  success: "",
  allUsers: "",
  usersItems: "",
  getclientsbyid: "",
  orderColumns: [
    { column: "username", label: "User Name" },
    { column: "companyname", label: "Company Name" },
    { column: "signal_quality", label: "Signal Quality" },
    { column: "HR_bpm", label: "HR bpm" },
    { column: "recorded_dt", label: "Recorded Date" },
  ],
  categories: ["Development", "Worklplace", "Hardware"],
  allTags: [],
  TagsData: [],
  info: {},
  data: [],
  count: 0,
  annotations: [[]],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RECORDINGS_GET_LIST:
      return { ...state, loading: false };

    case RECORDINGS_GET_LIST_BY_ID:
      return { ...state, loading: false };

    case RECORDINGS_GET_LIST_BY_USER_ID:
      return { ...state, loading: false };

    case RECORDINGS_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        allRecordings: action.payload.data.data,
        recordingItems: action.payload.data.data,
      };

    case RECORDINGS_GET_LIST_BY_DATES:
      return { ...state, loading: false };

    case RECORDINGS_GET_LIST_BY_DATES_SUCCESS:
      return {
        ...state,
        loading: true,
        allRecordings: action.payload.data.data,
        recordingItems: action.payload.data.data,
      };

    case RECORDINGS_GET_LIST_BY_DATES_ERROR:
      return { ...state, loading: false, error: action.payload };

    case RECORDINGS_GET_LIST_BY_DATES_COMPANY:
      return { ...state, loading: false };

    case RECORDINGS_GET_LIST_BY_DATES_COMPANY_SUCCESS:
      return {
        ...state,
        loading: true,
        allRecordings: action.payload.data.data,
        recordingItems: action.payload.data.data,
      };

    case RECORDINGS_GET_LIST_BY_DATES_COMPANY_ERROR:
      return { ...state, loading: false, error: action.payload };

    case RECORDINGS_GET_LIST_BY_DATES_USER:
      return { ...state, loading: false };

    case RECORDINGS_GET_LIST_BY_DATES_USER_SUCCESS:
      return {
        ...state,
        loading: true,
        allRecordings: action.payload.data.data,
        recordingItems: action.payload.data.data,
      };

    case RECORDINGS_GET_LIST_BY_DATES_USER_ERROR:
      return { ...state, loading: false, error: action.payload };

    case RECORDINGS_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case RECORDINGS_GET_LIST_BY_ID_SUCCESS:
      return {
        ...state,
        loading: true,
        allRecordings: action.payload.data.data,
        recordingItems: action.payload.data.data,
      };

    case RECORDINGS_GET_LIST_BY_ID_ERROR:
      return { ...state, loading: false, error: action.payload };

    case RECORDINGS_GET_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        loading: true,
        allRecordings: action.payload.data.data,
        recordingItems: action.payload.data.data,
      };

    case RECORDINGS_GET_LIST_BY_USER_ID_ERROR:
      return { ...state, loading: false, error: action.payload };

    case RECORDINGS_GET_SEARCH_LIST_BY_TERM:
      return { ...state, loading: false };

    case RECORDINGS_GET_SEARCH_LIST_BY_TERM_SUCCESS:
      return {
        ...state,
        loading: true,
        allRecordings: action.payload.data.data,
        recordingItems: action.payload.data.data,
      };

    case RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM:
      return { ...state, loading: false };

    case RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM_SUCCESS:
      return {
        ...state,
        loading: true,
        allRecordings: action.payload.data.data,
        recordingItems: action.payload.data.data,
      };
    case RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM_ERROR:
      return { ...state, loading: false, error: action.payload };

    case RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM:
      return { ...state, loading: false };

    case RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM_SUCCESS:
      return {
        ...state,
        loading: true,
        allRecordings: action.payload.data.data,
        recordingItems: action.payload.data.data,
      };
    case RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM_ERROR:
      return { ...state, loading: false, error: action.payload };

    case RECORDINGS_LIST_BY_ORDER:
      return { ...state, loading: false };

    case RECORDINGS_LIST_BY_ORDER_SUCCESS:
      return {
        ...state,
        loading: true,
        allRecordings: action.payload.data.data,
        recordingItems: action.payload.data.data,
      };
    case RECORDINGS_LIST_BY_ORDER_ERROR:
      return { ...state, loading: false, error: action.payload };

    case RECORDINGS_LIST_BY_ORDER_BY_CLIENT:
      return { ...state, loading: false };

    case RECORDINGS_LIST_BY_ORDER_BY_CLIENT_SUCCESS:
      return {
        ...state,
        loading: true,
        allRecordings: action.payload.data.data,
        recordingItems: action.payload.data.data,
      };
    case RECORDINGS_LIST_BY_ORDER_BY_CLIENT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case RECORDINGS_LIST_BY_ORDER_BY_USER:
      return { ...state, loading: false };

    case RECORDINGS_LIST_BY_ORDER_BY_USER_SUCCESS:
      return {
        ...state,
        loading: true,
        allRecordings: action.payload.data.data,
        recordingItems: action.payload.data.data,
      };
    case RECORDINGS_LIST_BY_ORDER_BY_USER_ERROR:
      return { ...state, loading: false, error: action.payload };

    case RECORDINGS_GET_TAGS:
      return { ...state, loading: false };

    case RECORDINGS_GET_TAGS_SUCCESS:
      return {
        ...state,
        loading: true,
        allTags: action.payload.data.data,
        TagsData: action.payload.data.data,
      };
    case RECORDINGS_GET_TAGS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case RECORDING_LIST_GET_LIST_WITH_FILTER:
      if (action.payload.column === "" || action.payload.value === "") {
        return {
          ...state,
          loading: true,
          allRecordings: state.recordingItems,
          filter: null,
        };
      } else {
        const filteredItems = state.allRecordings.filter(
          (item) =>
            item[action.payload.column] &&
            item[action.payload.column].indexOf(action.payload.value) > -1
        );
        //console.log(filteredItems);

        return {
          ...state,
          loading: true,
          allRecordings: filteredItems,
          filter: {
            column: action.payload.column,
            value: action.payload.value,
          },
        };
      }

    case RECORDING_DELETE:
      console.log("working RECORDING_DELETE");
      const filteredItems = state.allRecordings.filter(
        (item) => item.id != action.payload
      );
      console.log("case calling check: ", action.payload);
      // console.log(filteredItems);
      return {
        ...state,
        loading: true,
        allRecordings: filteredItems,
      };

    case RECORDING_PAGINATION:
      let all_recordings = [];

      if (action.page_type == "default" || action.page_type === "new") {
        all_recordings = [...action.payload];
      } else {
        all_recordings = [...state.allRecordings, ...action.payload];
      }

      return { ...state, loading: true, allRecordings: all_recordings };

    case SET_LOADER:
      console.log("working Loader");
      return { loading: false };

    case "RECEIVE_JSON":
      let newState = {
        data: action.data.data,
        info: action.data.info,
        count: state.count,
      };
      newState.annotations =
        action.data.annotations.length === 0
          ? [[]]
          : action.data.annotations || [[]];
      newState.file_name = action.file_name;
      return Object.assign({}, newState);
    case "ADD_HEARTBEAT":
      const copyAnnotationsForAdd = state.annotations.slice();
      copyAnnotationsForAdd.push([]);
      return {
        ...state,
        annotations: copyAnnotationsForAdd,
        count: copyAnnotationsForAdd.length - 1,
      };
    case "ADD_ANNOTATIONS":
      const copyAnnotationsForMarkers = state.annotations.slice();
      if (
        Object.prototype.toString.call(
          copyAnnotationsForMarkers[state.count]
        ) === "[object Array]"
      ) {
        let findMarker = copyAnnotationsForMarkers[state.count].find(
          (_) => _.name === action.paramName
        );
        if (findMarker) {
          findMarker.data = action.data;
        } else {
          copyAnnotationsForMarkers[state.count].push({
            name: action.paramName,
            data: { ...action.data },
          });
        }
        return { ...state, annotations: copyAnnotationsForMarkers };
      }
      return state;
    case "REMOVE_HEARTBEAT":
      const copyAnnotationsForRemove = state.annotations.slice();
      copyAnnotationsForRemove.pop();
      return {
        ...state,
        annotations: copyAnnotationsForRemove,
        count: copyAnnotationsForRemove.length - 1,
      };
    case "NEXT_HEARTBEAT":
      if (state.count + 1 < state.annotations.length) {
        return Object.assign({}, { ...state, count: state.count + 1 });
      }
      return state;
    case "PREV_HEARTBEAT":
      if (state.count - 1 >= 0) {
        return Object.assign({}, { ...state, count: state.count - 1 });
      }
      return state;
    case "CHANGE_USER_INFO":
      const { field_name } = action;
      return { ...state, info: { ...state.info, [field_name]: action.value } };

    default:
      return { ...state };
  }
};
