import {
    REQUEST_FILES, RECEIVE_FILES
} from '../actions';

function files(state = {
    isFetching: false,
    files: []
}, action) {
    switch (action.type) {
        case REQUEST_FILES:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case RECEIVE_FILES:
            return Object.assign({}, state, {
                isFetching: false,
                files: action.files,
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
}
function filesFromServer(state = { }, action) {
    switch (action.type) {
        case RECEIVE_FILES:
        case REQUEST_FILES:
            return Object.assign({}, state,
                files(state, action)
            );
        default:
            return state
    }
}

export default filesFromServer