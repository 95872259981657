import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getDateWithFormat } from "../../helpers/Utils";
import { CLIENT_GET_LIST, CLIENT_ADD_ITEM , CLIENT_DELETE_ITEM, USER_ADD_ITEM,USER_ADD_SUCCESS,USER_ADD_ERROR, USER_GET_LIST, USER_LIST_SUCCESS, USER_GET_LIST_ERROR, USER_DElETE_ITEM,CLIENT_GET_LIST_BY_ID,

    CLIENT_GET_SEARCH_LIST_BY_TERM,
    CLIENT_GET_SEARCH_LIST_BY_TERM_SUCCESS,
    CLIENT_GET_SEARCH_LIST_BY_TERM_ERROR,
    CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM,
    CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM_SUCCESS,
    CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM_ERROR,
    CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM,
    CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM_SUCCESS,
    CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM_ERROR,
    USER_GET_LIST_BY_COMPANY,
    USER_GET_LIST_BY_COMPANY_SUCCESS,
    USER_GET_LIST_BY_COMPANY_ERROR,
    ORG_GET_SEARCH_LIST_BY_TERM_SUCCESS,
    ORG_GET_SEARCH_LIST_BY_TERM_ERROR,
    ORG_GET_SEARCH_LIST_BY_TERM,


} from "../actions";
import  Authservice  from '../../helpers/Authservice';
import Dataservice from '../../helpers/Dataservice';
import todoData from "../../data/todos.json";

import {
  getClientListSuccess,
  getClientListError,
  addClientSuccess,
  addClientError,
  deleteClientSuccess,
  deleteClientError,
  addUserItem,
  addUserError,
  addUserSuccess,
  getUsersListSuccess,
  getUsersListError,
  getClientbyIdListSuccess,
  getClientListbyIdError,
  getRecordingsSearchListTermSuccess,
  getRecordingsSearchListTermError,
  getClientSearchListListByIdSuccess,
  getClientSearchListListByIdError,
  getClientSearchListListByUserId,
  getClientSearchListListByUserIdSuccess,
  getClientSearchListListByUserIdError,
  getUsersListbycompanyId,
  getUsersListbycompanySuccess,
  getUsersListbyCompanyError,
  getOrgSearchListSuccess,
  getOrgSearchListError,
  getOrgSearchList,

} from "./actions";


const getClientListRequest = async () => 
  Authservice.getClientList()
        .then(response => response)
        .catch(error => error);


function* getClientListItems() {
  try {
    const response = yield call(getClientListRequest);


    yield put(getClientListSuccess(response));
  } catch (error) {
    yield put(getClientListError(error));
  }
}



const getClientListbyId = async (item) => 

  Authservice.getClientListbyId(item)
        .then(response => response)
        .catch(error => error);


function* getclientlistid({payload})
{

 try {


    const response = yield call(getClientListbyId,payload);
    yield put(getClientbyIdListSuccess(response));
  } catch (error) {
    yield put(getClientListbyIdError(error));
  }

}

const addClientItemRequest = async(item) => 

await Authservice.addclient(item)
        .then(response => response)
        .catch(error => error);

function* addClientItem({ payload }) {

  try {
    const response444 = yield call(addClientItemRequest, payload);
   // yield put(addClientError(error));

    yield put(addClientSuccess(response444.data));
  } catch (error) {

    yield put(addClientError(error));
  }
}


const addUserItemRequest = async(item) => 


await Authservice.adduser(item)
        .then(response => response)
        .catch(error => error);

function* addUserItems({payload})
{

try {


  console.log(payload);
  const response = yield call(addUserItemRequest, payload);
    
   // yield put(addClientError(error));
    yield put(addUserSuccess(response.message));
  } catch (error) {
    yield put(addUserError(error));
  }

}

const deleteClientItemRequest = async (id) => 
 await Authservice.deleteClientItem(id)
        .then(response => response)
        .catch(error => error);

function* deleteClientItem({ payload })
{

   try {

    const responsedelete = yield call(deleteClientItemRequest, payload);

    //yield put(addClientError(error));

    window.location.reload(false);
    yield put(deleteClientSuccess(responsedelete));
  } catch (error) {
    yield put(deleteClientError(error));
  }

}

const deleteUserItemRequest = async (id) => 
 await Authservice.deleteUserItem(id)
        .then(response => response)
        .catch(error => error);


function* deleteUserItem({ payload })
{

   try {
    const responsedelete = yield call(deleteUserItemRequest, payload);
    //yield put(addClientError(error));
    window.location.reload(false);
    yield put(deleteClientSuccess(responsedelete));
    } catch (error) {
    yield put(deleteClientError(error));
  }

}



const getUserListRequest = async () => 
  Authservice.getUserList()
        .then(response => response)
        .catch(error => error);

  function* getUserListItems() {
    try {
      const response = yield call(getUserListRequest);

      yield put(getUsersListSuccess(response));
    } catch (error) {
      yield put(getUsersListError(error));
    }
  }

const getUserListybycompanyItems_callback = async (payload) => 
  Authservice.getUserListbyCompany(payload)
             .then(response => response)
             .catch(error => error);

  function* getUserListybycompanyItems({payload}) {
    try {

      const response = yield call(getUserListybycompanyItems_callback,payload);
      yield put(getUsersListSuccess(response));
    } catch (error) {
      yield put(getUsersListError(error));
    }
  }



const getclientsearchlist_callback = async(payload)=>

  Dataservice.getclientsearchlist(payload)
        .then(response => response)
        .catch(error => error);


function* getclientsearchlist({payload}) {
  try {

     
    const response = yield call(getclientsearchlist_callback, payload);

    yield put(getRecordingsSearchListTermSuccess(response));
  } catch (error) {
      console.log(error);

  }
}

const getorgsearchlist_callback = async(payload)=>

  Dataservice.getorgsearchlist(payload)
        .then(response => response)
        .catch(error => error);


function* getorgsearchlist({payload}) {
  try {

     
    const response = yield call(getorgsearchlist_callback, payload);

    yield put(getOrgSearchListSuccess(response));
  } catch (error) {
      console.log(error);

  }
}






const getclientsearchlistid_callback = async(payload)=>

  Dataservice.getclientsearchlistid(payload)
        .then(response => response)
        .catch(error => error);


function* getclientsearchlistid({payload}) {
  try {

     
    const response = yield call(getclientsearchlistid_callback, payload);

    yield put(getClientSearchListListByIdSuccess(response));
  } catch (error) {
      console.log(error);

  }
}


const getclientsearchlistuserid_callback = async(payload)=>

  Dataservice.getclientsearchlistuserid(payload)
        .then(response => response)
        .catch(error => error);


function* getclientsearchlistuserid({payload}) {
  try {

     
    const response = yield call(getclientsearchlistuserid_callback, payload);

    yield put(getClientSearchListListByUserIdSuccess(response));
  } catch (error) {
      console.log(error);

  }
}


export function* watchGetList() {
  yield takeEvery(CLIENT_GET_LIST, getClientListItems);
}

export function* wathcAddItem() {
  yield takeEvery(CLIENT_ADD_ITEM, addClientItem);
}

export function* wathcAddUserItem() {
  yield takeEvery(USER_ADD_ITEM, addUserItems);
}


export function* watchGetUserList() {
  yield takeEvery(USER_GET_LIST, getUserListItems);
}

export function* watchGetUserListbyCompany() {
  yield takeEvery(USER_GET_LIST_BY_COMPANY, getUserListybycompanyItems);
}





export function* wathcDeleteItem()
{

   yield takeEvery(CLIENT_DELETE_ITEM, deleteClientItem);
}

export function* wathcUserDeleteItem()
{

   yield takeEvery(USER_DElETE_ITEM, deleteUserItem);
}

export function* watchgetclientlist()
{
  yield takeEvery(CLIENT_GET_LIST_BY_ID, getclientlistid);

}

export function* watchSearchclientlistbyterm()
{
  yield takeEvery(CLIENT_GET_SEARCH_LIST_BY_TERM, getclientsearchlist);

}

export function* watchSearchOrglistbyterm()
{
  yield takeEvery(ORG_GET_SEARCH_LIST_BY_TERM, getorgsearchlist);

}



export function* watchSearchclientlistbyIdandterm()
{
  yield takeEvery(CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM, getclientsearchlistid);

}

export function* watchSearchclientlistbyUserIdandterm()
{
  yield takeEvery(CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM, getclientsearchlistuserid);

}

                                                                                                                                      

export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcAddItem), fork(wathcDeleteItem), fork(wathcAddUserItem), fork(watchGetUserList), fork(wathcUserDeleteItem),fork(watchgetclientlist), fork(watchSearchclientlistbyterm), fork(watchSearchclientlistbyIdandterm), fork(watchSearchclientlistbyUserIdandterm), fork(watchGetUserListbyCompany),fork(watchSearchOrglistbyterm)]);
}
