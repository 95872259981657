/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const START_INACTIVITY_TIMER = 'START_INACTIVITY_TIMER';
export const STOP_INACTIVITY_TIMER = 'STOP_INACTIVITY_TIMER';
export const USER_ACTIVITY = 'USER_ACTIVITY';

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* TODOAPP*/
export const TODO_GET_LIST = "TODO_GET_LIST";
export const TODO_GET_LIST_SUCCESS = "TODO_GET_LIST_SUCCESS";
export const TODO_GET_LIST_ERROR = "TODO_GET_LIST_ERROR";
export const TODO_GET_LIST_WITH_FILTER = "TODO_GET_LIST_WITH_FILTER";
export const TODO_GET_LIST_WITH_ORDER = "TODO_GET_LIST_WITH_ORDER";
export const TODO_GET_LIST_SEARCH = "TODO_GET_LIST_SEARCH";
export const TODO_ADD_ITEM = "TODO_ADD_ITEM";
export const TODO_ADD_ITEM_SUCCESS = "TODO_ADD_ITEM_SUCCESS";
export const TODO_ADD_ITEM_ERROR = "TODO_ADD_ITEM_ERROR";
export const TODO_SELECTED_ITEMS_CHANGE = "TODO_SELECTED_ITEMS_CHANGE";



/*     CLIENT_GET_LIST,
    CLIENT_LIST_SUCCESS,
    CLIENT_GET_LIST_ERROR,
    
    CLIENT_GET_LIST_SEARCH,
    CLIENT_ADD_ITEM,
    ADD_CLIENT_SUCCESS,
    ADD_CLIENT_ERROR, */


//org search list



export const ORG_GET_SEARCH_LIST_BY_TERM_SUCCESS = "ORG_GET_SEARCH_LIST_BY_TERM_SUCCESS";
export const ORG_GET_SEARCH_LIST_BY_TERM_ERROR = "ORG_GET_SEARCH_LIST_BY_TERM_ERROR";
export const ORG_GET_SEARCH_LIST_BY_TERM = "ORG_GET_SEARCH_LIST_BY_TERM";






export const CLIENT_GET_LIST = "CLIENT_GET_LIST";
export const CLIENT_LIST_SUCCESS = "CLIENT_LIST_SUCCESS";
export const CLIENT_GET_LIST_ERROR = "CLIENT_GET_LIST_ERROR";
export const CLIENT_GET_LIST_SEARCH = "CLIENT_GET_LIST_SEARCH";
export const CLIENT_ADD_ITEM = "CLIENT_ADD_ITEM";
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_ERROR = "ADD_CLIENT_ERROR";
export const CLIENT_DELETE_ITEM = "CLIENT_DELETE_ITEM";
export const CLIENT_DELETE_ERROR = "CLIENT_DELETE_ERROR";
export const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
export const CLIENT_GET_LIST_BY_ID = "CLIENT_GET_LIST_BY_ID";
export const CLIENT_LIST_BY_ID_SUCCESS = "CLIENT_LIST_BY_ID_SUCCESS";
export const CLIENT_LIST_BY_ID_ERROR = "CLIENT_LIST_BY_ID_ERROR";
export const CLIENT_GET_SEARCH_LIST_BY_TERM = "CLIENT_GET_SEARCH_LIST_BY_TERM";
export const CLIENT_GET_SEARCH_LIST_BY_TERM_SUCCESS = "CLIENT_GET_SEARCH_LIST_BY_TERM_SUCCESS";
export const CLIENT_GET_SEARCH_LIST_BY_TERM_ERROR = "CLIENT_GET_SEARCH_LIST_BY_TERM_ERROR";
export const CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM = "CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM";
export const CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM_SUCCESS = "CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM_SUCCESS";
export const CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM_ERROR = "CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM_ERROR";
export const CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM = "CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM";
export const CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM_SUCCESS = "CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM_SUCCESS";
export const CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM_ERROR = "CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM_ERROR";


export const USER_ADD_ITEM = "USER_ADD_ITEM";
export const USER_ADD_SUCCESS = "USER_ADD_SUCCESS";
export const USER_ADD_ERROR = "USER_ADD_ERROR";
export const USER_DElETE_ITEM = "USER_DElETE_ITEM";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_ERROR = "USER_DELETE_ERROR";
export const USER_GET_LIST = "USER_GET_LIST";

// Add Pagination on user listing 26 sep 2023 by cts
export const USER_GET_LIST_PAGINATION = "USER_GET_LIST_PAGINATION";

export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_GET_LIST_ERROR = "USER_GET_LIST_ERROR";
export const GET_CLIENT_LIST_BY_ID = "GET_CLIENT_LIST_BY_ID";
export const USER_GET_LIST_BY_COMPANY = "USER_GET_LIST_BY_COMPANY";
export const USER_GET_LIST_BY_COMPANY_SUCCESS = "USER_GET_LIST_BY_COMPANY_SUCCESS";
export const USER_GET_LIST_BY_COMPANY_ERROR = "USER_GET_LIST_BY_COMPANY_ERROR";


/* RECORDINGS */
export const RECORDINGS_GET_LIST = 'RECORDINGS_GET_LIST';
export const RECORDINGS_LIST_SUCCESS = 'RECORDINGS_LIST_SUCCESS';
export const RECORDINGS_GET_LIST_ERROR = 'RECORDINGS_GET_LIST_ERROR';
export const RECORDINGS_GET_LIST_BY_ID = 'RECORDINGS_GET_LIST_BY_ID';

export const RECORDINGS_GET_LIST_BY_DATES = 'RECORDINGS_GET_LIST_BY_DATES';
export const RECORDINGS_GET_LIST_BY_DATES_SUCCESS = 'RECORDINGS_GET_LIST_BY_DATES_SUCCESS';
export const RECORDINGS_GET_LIST_BY_DATES_ERROR = 'RECORDINGS_GET_LIST_BY_DATES_ERROR';


export const RECORDINGS_GET_LIST_BY_DATES_COMPANY = 'RECORDINGS_GET_LIST_BY_DATES_COMPANY';
export const RECORDINGS_GET_LIST_BY_DATES_COMPANY_SUCCESS = 'RECORDINGS_GET_LIST_BY_DATES_COMPANY_SUCCESS';
export const RECORDINGS_GET_LIST_BY_DATES_COMPANY_ERROR = 'RECORDINGS_GET_LIST_BY_DATES_COMPANY_ERROR';


export const RECORDINGS_GET_LIST_BY_DATES_USER = 'RECORDINGS_GET_LIST_BY_DATES_USER';
export const RECORDINGS_GET_LIST_BY_DATES_USER_SUCCESS = 'RECORDINGS_GET_LIST_BY_DATES_USER_SUCCESS';
export const RECORDINGS_GET_LIST_BY_DATES_USER_ERROR = 'RECORDINGS_GET_LIST_BY_DATES_USER_ERROR';

 
export const RECORDINGS_GET_LIST_BY_ID_SUCCESS = 'RECORDINGS_GET_LIST_BY_ID_SUCCESS';
export const RECORDINGS_GET_LIST_BY_ID_ERROR = 'RECORDINGS_GET_LIST_BY_ID_ERROR';
export const RECORDINGS_GET_LIST_BY_USER_ID = 'RECORDINGS_GET_LIST_BY_USER_ID';
export const RECORDINGS_GET_LIST_BY_USER_ID_SUCCESS = 'RECORDINGS_GET_LIST_BY_USER_ID_SUCCESS';
export const RECORDINGS_GET_LIST_BY_USER_ID_ERROR = 'RECORDINGS_GET_LIST_BY_USER_ID_ERROR';
export const RECORDINGS_GET_SEARCH_LIST_BY_TERM = 'RECORDINGS_GET_SEARCH_LIST_BY_TERM';
export const RECORDINGS_GET_SEARCH_LIST_BY_TERM_SUCCESS = 'RECORDINGS_GET_SEARCH_LIST_BY_TERM_SUCCESS';
export const RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM = 'RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM';
export const RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM_SUCCESS = 'RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM_SUCCESS';
export const RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM_ERROR = 'RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM_ERROR';
export const RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM = "RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM";
export const RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM_SUCCESS = "RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM_SUCCESS";
export const RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM_ERROR = "RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM_ERROR";
export const RECORDINGS_LIST_BY_ORDER = "RECORDINGS_LIST_BY_ORDER";
export const RECORDINGS_LIST_BY_ORDER_ERROR = "RECORDINGS_LIST_BY_ORDER_ERROR";
export const RECORDINGS_LIST_BY_ORDER_SUCCESS = "RECORDINGS_LIST_BY_ORDER_SUCCESS";
export const RECORDING_LIST_GET_LIST_WITH_FILTER = "RECORDING_LIST_GET_LIST_WITH_FILTER";
export const RECORDINGS_LIST_BY_ORDER_BY_CLIENT = "RECORDINGS_LIST_BY_ORDER_BY_CLIENT";
export const RECORDINGS_LIST_BY_ORDER_BY_CLIENT_ERROR = "RECORDINGS_LIST_BY_ORDER_BY_CLIENT_ERROR";
export const RECORDINGS_LIST_BY_ORDER_BY_CLIENT_SUCCESS = "RECORDINGS_LIST_BY_ORDER_BY_CLIENT_SUCCESS";
export const RECORDINGS_LIST_BY_ORDER_BY_USER = "RECORDINGS_LIST_BY_ORDER_BY_USER";
export const RECORDINGS_LIST_BY_ORDER_BY_USER_SUCCESS = "RECORDINGS_LIST_BY_ORDER_BY_USER_SUCCESS";
export const RECORDINGS_LIST_BY_ORDER_BY_USER_ERROR = "RECORDINGS_LIST_BY_ORDER_BY_USER_ERROR";
export const RECORDINGS_GET_TAGS = "RECORDINGS_GET_TAGS";
export const RECORDINGS_GET_TAGS_SUCCESS = "RECORDINGS_GET_TAGS_SUCCESS";
export const RECORDINGS_GET_TAGS_ERROR = "RECORDINGS_GET_TAGS_ERROR";

/* To delete the recording cts 14 sept */
export const RECORDING_DELETE = "RECORDING_DELETE";

// Add Pagination for new recording API 21 sep CTS
export const RECORDING_PAGINATION = "RECORDING_PAGINATION";
export const SET_LOADER = "SET_LOADER";

/* Graph Actions */


export const RECEIVE_JSON = 'RECEIVE_JSON';
export const SELECT_PARAM = 'SELECT_PARAM';
export const RECEIVE_FILES = 'RECEIVE_FILES';
export const REQUEST_FILES = 'REQUEST_FILES';
export const ADD_ANNOTATIONS = 'ADD_ANNOTATIONS';
export const REMOVE_PARAMS = 'REMOVE_PARAMS';
export const NEXT_HEARTBEAT = 'NEXT_HEARTBEAT';
export const PREV_HEARTBEAT = 'PREV_HEARTBEAT';
export const ADD_HEARTBEAT = 'ADD_HEARTBEAT';
export const REMOVE_HEARTBEAT = 'REMOVE_HEARTBEAT';
export const CHANGE_USER_INFO = 'CHANGE_USER_INFO';
export const SEND_REQUEST_FILE = 'SEND_REQUEST_FILE';


 


/* CHAT APP*/
export const CHAT_GET_CONTACTS = "CHAT_GET_CONTACTS";
export const CHAT_GET_CONTACTS_SUCCESS = "CHAT_GET_CONTACTS_SUCCESS";
export const CHAT_GET_CONTACTS_ERROR = "CHAT_GET_CONTACTS_ERROR";
export const CHAT_GET_CONVERSATIONS = "CHAT_GET_CONVERSATIONS";
export const CHAT_GET_CONVERSATIONS_SUCCESS = "CHAT_GET_CONVERSATIONS_SUCCESS";
export const CHAT_GET_CONVERSATIONS_ERROR = "CHAT_GET_CONVERSATIONS_ERROR";
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  "CHAT_ADD_MESSAGE_TO_CONVERSATION";
export const CHAT_CREATE_CONVERSATION = "CHAT_CREATE_CONVERSATION";
export const CHAT_SEARCH_CONTACT = "CHAT_SEARCH_CONTACT";
export const CHAT_CHANGE_CONVERSATION = "CHAT_CHANGE_CONVERSATION";

/* SURVEY LIST APP*/
export const SURVEY_LIST_GET_LIST = "SURVEY_LIST_GET_LIST";
export const SURVEY_LIST_GET_LIST_SUCCESS = "SURVEY_LIST_GET_LIST_SUCCESS";
export const SURVEY_LIST_GET_LIST_ERROR = "SURVEY_LIST_GET_LIST_ERROR";
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  "SURVEY_LIST_GET_LIST_WITH_FILTER";
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  "SURVEY_LIST_GET_LIST_WITH_ORDER";
export const SURVEY_LIST_GET_LIST_SEARCH = "SURVEY_LIST_GET_LIST_SEARCH";
export const SURVEY_LIST_ADD_ITEM = "SURVEY_LIST_ADD_ITEM";
export const SURVEY_LIST_ADD_ITEM_SUCCESS = "SURVEY_LIST_ADD_ITEM_SUCCESS";
export const SURVEY_LIST_ADD_ITEM_ERROR = "SURVEY_LIST_ADD_ITEM_ERROR";
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  "SURVEY_LIST_SELECTED_ITEMS_CHANGE";

/* SURVEY DETAIL APP*/
export const SURVEY_GET_DETAILS = "SURVEY_GET_DETAILS";
export const SURVEY_GET_DETAILS_SUCCESS = "SURVEY_GET_DETAILS_SUCCESS";
export const SURVEY_GET_DETAILS_ERROR = "SURVEY_GET_DETAILS_ERROR";
export const SURVEY_DELETE_QUESTION = "SURVEY_DELETE_QUESTION";
export const SURVEY_SAVE = "SURVEY_SAVE";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./todo/actions";
export * from "./chat/actions";
export * from "./surveyList/actions";
export * from "./surveyDetail/actions";
export * from "./client/actions";
export * from "./recordings/actions";
export * from "./graph/actions";
