/**
 * @file auth.sagas.js
 * @memberof Auth
 * @description Redux Saga functions for handling authentication processes including
 * login, registration, logout, password reset, and other related operations.
 */

import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { auth } from "../../helpers/Firebase";
import Authservice from "../../helpers/Authservice";
import { setUserSession } from "../../Utils/Common";
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from "../actions";
import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from "./actions";

/**
 * Watches for LOGIN_USER action and calls loginWithEmailPassword saga
 */
export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

/**
 * Performs login with email and password
 */
const loginWithEmailPasswordAsync = async (username, password) =>
  await Authservice.login(username, password)
    .then((authUser) => authUser)
    .catch((error) => error);

/**
 * Handles the login process
 */
function* loginWithEmailPassword({ payload }) {
  const { username, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(
      loginWithEmailPasswordAsync,
      username,
      password
    );
    if (!loginUser.message) {
      // Store user data in localStorage
      localStorage.setItem("user_id", loginUser.user.userId);
      localStorage.setItem("client_id", loginUser.user.client_id);
      localStorage.setItem("isAdmin", loginUser.user.isAdmin);
      localStorage.setItem("isClientAdmin", loginUser.user.isClientAdmin);
      localStorage.setItem(
        "is_password_change",
        loginUser.user.is_password_change
      );
      localStorage.setItem(
        "password_change_required",
        loginUser.user.password_change_required
      );
      // Dispatch success action
      yield put(loginUserSuccess(loginUser.user));

      // Dispatch custom event to trigger inactivity timer
      window.dispatchEvent(new Event("user-logged-in"));

      // Navigate based on user role
      history.push("/app/dashboard");
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(
      loginUserError(error.message || "An error occurred during login")
    );
  }
}

/**
 * Watches for REGISTER_USER action and calls registerWithEmailPassword saga
 */
export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

/**
 * Registers a new user with email and password
 */
const registerWithEmailPasswordAsync = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

/**
 * Handles the user registration process
 */
function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      localStorage.setItem("user_id", registerUser.user.uid);
      yield put(registerUserSuccess(registerUser));
      history.push("/");
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

/**
 * Watches for LOGOUT_USER action and calls logout saga
 */
export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

/**
 * Logs out the user
 */
const logoutAsync = async (history) => {
  try {
    await Authservice.logout();
    history.push("/"); // Redirect to root URL after successful logout
  } catch (error) {
    console.error("Error during logoutAsync:", error); // Log any error that occurs
  }
};

/**
 * Handles the user logout process
 * Note: Automatic logout for inactivity is handled separately in the App component
 */
function* logout({ payload }) {
  const { history } = payload;

  try {
    yield call(logoutAsync, history);
  } catch (error) {
    console.error("Error in logout saga:", error.message || error);
  }
}

/**
 * Watches for FORGOT_PASSWORD action and calls forgotPassword saga
 */
export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

/**
 * Sends a password reset email
 */
const forgotPasswordAsync = async (email) => {
  return await Authservice.sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => {
      console.error("Error in forgotPasswordAsync:", error);
      return error;
    });
};

/**
 * Handles the forgot password process
 */
function* forgotPassword({ payload }) {
  const { emailOrUsername } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(
      forgotPasswordAsync,
      emailOrUsername
    );
    if (forgotPasswordStatus && forgotPasswordStatus.data) {
      if (forgotPasswordStatus.data.status === true) {
        yield put(forgotPasswordSuccess("success"));
      } else {
        yield put(forgotPasswordError(forgotPasswordStatus.data.message));
      }
    } else {
      yield put(forgotPasswordError("Invalid response format from server"));
    }
  } catch (error) {
    console.error("Error in forgotPassword saga:", error.message);
    yield put(
      forgotPasswordError(error.response?.data?.message || error.message)
    );
  }
}

/**
 * Watches for RESET_PASSWORD action and calls resetPassword saga
 */
export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

/**
 * Resets the user's password
 */
const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return await Authservice.sendPasswordResetEmailusers(
    resetPasswordCode,
    newPassword
  )
    .then((user) => user)
    .catch((error) => error);
};

/**
 * Handles the password reset process
 */
function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (resetPasswordStatus.data.status === true) {
      yield put(resetPasswordSuccess("success"));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

/**
 * Root saga that forks all watcher sagas
 */
export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
