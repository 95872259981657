/**
 * @file AuthService.js
 * @memberof Auth
 * @description Provides authentication and user management services for the application.
 */

import React, { useState } from "react";
import axios from "axios";
import { setUserSession } from "../Utils/Common";
import { Route, Redirect } from "react-router-dom";

const API_URL = process.env.REACT_APP_SERVER_URL;

/**
 * AuthService class for handling authentication and user management operations.
 */
class AuthService {
  /**
   * Authenticates a user and sets up their session.
   * @param {string} username - The user's username.
   * @param {string} password - The user's password.
   * @returns {Promise<Object>} The user data and token.
   */
  login(username, password) {
    return axios
      .post(API_URL + "users/signin", { username, password })
      .then((response) => {
        setUserSession(response.data.token, response.data.user);
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("user_id", response.data.user.userId);
        }
        return response.data;
      });
  }

  /**
   * Logs out the current user by removing their session data.
   * @returns {Promise<void>}
   */
  logout() {
    return new Promise((resolve, reject) => {
      try {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("user");
        localStorage.removeItem("user_id");
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Adds a new client to the system.
   * @param {Object} item - The client data.
   * @returns {Promise<Object>} The response from the server.
   */
  addclient(item) {
    const {
      title,
      username,
      status,
      password,
      middlename,
      lastname,
      height,
      healthinfo,
      gender,
      firstname,
      facilitysite,
      email,
      dob,
      clientref,
      bodymass,
    } = item;

    return axios
      .post(API_URL + "add-clients", {
        title,
        status,
        username,
        password,
        middlename,
        lastname,
        height,
        healthinfo,
        gender,
        firstname,
        facilitysite,
        email,
        dob,
        clientref,
        bodymass,
      })
      .then((response) => response);
  }

  /**
   * Retrieves the list of all clients.
   * @returns {Promise<Object>} The response containing the client list.
   */
  getClientList() {
    return axios.get(API_URL + "all-clients");
  }

  /**
   * Retrieves a client's information by their ID.
   * @param {number} id - The client's ID.
   * @returns {Promise<Object>} The response containing the client's information.
   */
  getClientListbyId(id) {
    return axios.post(API_URL + "get-client-list-by-id", { id });
  }

  /**
   * Deletes a client from the system.
   * @param {number} id - The ID of the client to delete.
   * @returns {Promise<Object>} The response from the server.
   */
  deleteClientItem(id) {
    return axios.post(API_URL + "delete-clients", { id });
  }

  /**
   * Deletes a user from the system.
   * @param {number} id - The ID of the user to delete.
   * @returns {Promise<Object>} The response from the server.
   */
  deleteUserItem(id) {
    return axios.post(API_URL + "delete-users", { id });
  }

  /**
   * Adds a new user to the system.
   * @param {Object} item - The user data.
   * @returns {Promise<Object>} The response from the server.
   */
  adduser(item) {
    const {
      companyid,
      bodymass,
      clientref,
      dob,
      email,
      facilitysite,
      firstname,
      lastname,
      gender,
      healthinfo,
      height,
      middlename,
      password,
      status,
      username,
    } = item;

    return axios.post(API_URL + "add-users", {
      client_id: parseInt(companyid),
      active: status,
      client_admin: 1,
      username,
      password,
      first_name: firstname,
      last_name: lastname,
      middle_initials: middlename,
      email,
      dob,
      gender,
      height_cm: parseInt(height),
      bodymass_kg: parseInt(bodymass),
      facility_site: facilitysite,
      health_info_json: healthinfo,
      client_ref: clientref,
    });
  }

  /**
   * Retrieves the list of all users.
   * @returns {Promise<Object>} The response containing the user list.
   */
  getUserList() {
    return axios.get(API_URL + "all-users");
  }

  /**
   * Retrieves the list of users for a specific company.
   * @param {Object} payload - The payload containing company information.
   * @returns {Promise<Object>} The response containing the user list.
   */
  getUserListbyCompany(payload) {
    return axios.post(API_URL + "all-users-by-cpmpany", { payload });
  }

  /**
   * Edits a company's name.
   * @param {number} id - The company's ID.
   * @param {string} name - The new name for the company.
   * @returns {Promise<Object>} The response from the server.
   */
  edit_company_name(id, name) {
    return axios.post(API_URL + "edit-company-records", { id, name });
  }

  /**
   * Edits a company's details.
   * @param {Object} item - The updated company details.
   * @returns {Promise<Object>} The response from the server.
   */
  edit_company_details(item) {
    const {
      clientid,
      bodymass_kg,
      clientinfo,
      email,
      facility_site,
      first_name,
      last_name,
      healthinfo,
      height_cm,
      middle_initials,
      password,
      status,
      username,
      id,
    } = item;

    return axios.post(API_URL + "edit-company-details", {
      id,
      first_name,
      last_name,
      middle_initials,
      email,
      height_cm: parseInt(height_cm),
      bodymass_kg: parseInt(bodymass_kg),
      facility_site,
      healthinfo,
      clientinfo,
      status,
      clientid: parseInt(clientid),
      password,
    });
  }

  /**
   * Sends a password reset email to the user.
   * @param {string} emailOrUsername - The user's email or username.
   * @returns {Promise<Object>} The response from the server.
   */
  sendPasswordResetEmail(emailOrUsername) {
    return axios
      .post(API_URL + "forgotpassword", { email: emailOrUsername })
      .catch((error) => {
        console.error("Error in sendPasswordResetEmail:", error.message);
        throw error;
      });
  }

  /**
   * Resets a user's password using a reset code.
   * @param {string} code - The password reset code.
   * @param {string} password - The new password.
   * @returns {Promise<Object>} The response from the server.
   */
  sendPasswordResetEmailusers(code, password) {
    return axios.post(API_URL + "reset-password-users", { code, password });
  }
}

export default new AuthService();
