import React, { useState } from "react";
import axios from "axios";
import { setUserSession } from "../Utils/Common";
import { Route, Redirect } from "react-router-dom";

// const API_URL = "http://127.0.0.1:4000/";
//const API_URL =process.env.REACT_APP_SERVER_URL;
//const API_URL = "https://admin.recordis.ca/";
const API_URL = process.env.REACT_APP_SERVER_URL

class Dataservice {
  
  /************ */ // pagination function 20 sep 2023 CTS
  // getRecordingsList(payload) {
  //   //let page = payload.page;     

  //   return axios.get(API_URL + "all-recordings?page="+payload).then((response) => {
  //       return response;
  //     });
  // }

  /************ */
  
  getRecordingsList() {
    return axios.get(API_URL + "all-recordings").then((response) => {
      return response;
    });
  }

  getRecordingsListbydates(payload) {
    let start = payload.startdate,
      end = payload.enddate,
      query = payload.query;

    return axios
      .post(API_URL + "all-recordings-by-dates", { start, end, query })
      .then((response) => {
        return response;
      });
  }

  getRecordingsListbydatesCompany(payload) {
    let start = payload.startdate,
      end = payload.enddate,
      companyid = payload.companyid,
      query = payload.query;

    return axios
      .post(API_URL + "all-recordings-by-dates-company", {
        start,
        end,
        companyid,
        query,
      })
      .then((response) => {
        return response;
      });
  }

  getRecordingsListbydatesUser(payload) {
    let start = payload.startdate,
      end = payload.enddate,
      userid = payload.userid,
      query = payload.query;

    return axios
      .post(API_URL + "all-recordings-by-dates-user", {
        start,
        end,
        userid,
        query,
      })
      .then((response) => {
        return response;
      });
  }

  getRecordingsListbyCompanyId(id) {
    return axios
      .post(API_URL + "all-recordings-by-company", { id })
      .then((response) => {
        return response;
      });
  }

  getRecordingsListbyUserid(id) {
    return axios
      .post(API_URL + "all-recordings-by-users", { id })
      .then((response) => {
        return response;
      });
  }
  // getRecordingsListbyUserid(id, page = 1) {
  //   return axios
  //     .get(`${API_URL}/all-recordings-by-users?userId=${id}&page=${page}`)
  //     .then((response) => {
  //       return response;
  //     });
  // }

  getclientsearchlist(query) {
    return axios
      .post(API_URL + "get-user-by-term", { query })
      .then((response) => {
        return response;
      });
  }

  getorgsearchlist(query) {
    return axios
      .post(API_URL + "get-org-by-term", { query })
      .then((response) => {
        return response;
      });
  }

  getRecordingssearchListterm(payload) {
    let query = payload.searchquery,
      start = payload.start,
      end = payload.end,
      companyid = payload.companyid,
      userid = payload.userid;

    return axios
      .post(API_URL + "get-search-by-term", {
        query,
        start,
        end,
        companyid,
        userid,
      })
      .then((response) => {
        return response;
      });
  }

  getclientsearchlistid(query) {
    let client_id = query.clientid,
      queryterm = query.searchquery;

    return axios
      .post(API_URL + "get-user-by-term-by-id", { client_id, queryterm })
      .then((response) => {
        return response;
      });
  }

  getRecordingssearchListtermandId(query) {
    let client_id = query.clientid,
      queryterm = query.searchquery;

    return axios
      .post(API_URL + "get-search-by-term-by-id", { client_id, queryterm })
      .then((response) => {
        return response;
      });
  }

  getclientsearchlistuserid(query) {
    let user_id = query.userid,
      queryterm = query.searchquery;

    return axios
      .post(API_URL + "get-user-by-term-by-userid", { user_id, queryterm })
      .then((response) => {
        return response;
      });
  }

  getRecordingssearchListuserid(query) {
    let user_id = query.userid,
      queryterm = query.searchquery;

    return axios
      .post(API_URL + "get-search-by-term-by-userid", { user_id, queryterm })
      .then((response) => {
        return response;
      });
  }

  getRecordingsListByOrderFetch(ordercolumn) {
    return axios
      .post(API_URL + "get-recording-list-by-order", { ordercolumn })
      .then((response) => {
        return response;
      });
  }

  getRecordingsListbyClientOrder(query) {
    let clientid = query.clientid,
      ordercolumn = query.column;
    return axios
      .post(API_URL + "get-recording-list-by-order-client-id", {
        clientid,
        ordercolumn,
      })
      .then((response) => {
        return response;
      });
  }

  getRecordingsListbyUserOrder(query) {
    let userid = query.userid,
      ordercolumn = query.column;

    return axios
      .post(API_URL + "get-recording-list-by-order-user-id", {
        userid,
        ordercolumn,
      })
      .then((response) => {
        return response;
      });
  }

  getRecordingsTagsP() {
    return axios.get(API_URL + "get-recording-tags").then((response) => {
      return response;
    });
  }
}

export default new Dataservice();
