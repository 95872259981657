import axios from "axios";
import FileDownload from "react-file-download";

import {
  RECEIVE_JSON,
  SELECT_PARAM,
  RECEIVE_FILES,
  REQUEST_FILES,
  ADD_ANNOTATIONS,
  REMOVE_PARAMS,
  NEXT_HEARTBEAT,
  PREV_HEARTBEAT,
  ADD_HEARTBEAT,
  REMOVE_HEARTBEAT,
  CHANGE_USER_INFO,
  SEND_REQUEST_FILE,
} from "../actions";

export const RESET_STATE = "RESET_STATE";

export function resetState() {
  return { type: RESET_STATE };
}

export function receiveJSON(json, file_name) {
  return {
    type: RECEIVE_JSON,
    file_name,
    data: json,
  };
}

//const API_URL = 'http://localhost:4000/';
// const URL = process.env.REACT_APP_SERVER_URL;
//const API_URL = "https://admin.recordis.ca/";
const API_URL = process.env.REACT_APP_SERVER_URL;

function requestFiles() {
  return {
    type: REQUEST_FILES,
  };
}

function receiveFiles(files) {
  return {
    type: RECEIVE_FILES,
    files,
    receivedAt: Date.now(),
  };
}

function fetchFiles() {
  return (dispatch) => {
    dispatch(requestFiles());
    return axios
      .get(`/files_list`)
      .then((response) => response)
      .then((json) => {
        dispatch(receiveFiles(json.data));
      });
  };
}

function shouldFetchFiles(state) {
  const files = state.files;
  if (!files) {
    return true;
  } else if (files.isFetching) {
    return false;
  } else {
    return true;
  }
}

function downloadReport(downloadablefile) {
  return (dispatch) => {
    return axios
      .get(`/download?file=` + downloadablefile, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileDownload(blob, downloadablefile);
        return;
      });
  };
}

export function sendFile(formaData) {
  return (dispatch) => {
    document.getElementById("PreLoaderBar").style.display = "block";
    return axios
      .post(`/upload`, formaData)
      .then(() => {
        dispatch(fetchFiles());
      })
      .then(() => {
        document.getElementById("PreLoaderBar").style.display = "none";
      });
  };
}

export function sendData() {
  return (dispatch, getState) => {
    return axios.post(`/save_file`, {
      annotations: getState().data.annotations,
      info: getState().data.info,
      file_name: getState().data.file_name,
    });
  };
}

export function fetchFilesIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchFiles(getState())) {
      return dispatch(fetchFiles());
    }
  };
}

export function fetchRecordingJson(file_name) {
  return (dispatch) => {
    return axios.get(API_URL + `fetchjson/${file_name}`).then((json) => {
      dispatch(receiveJSON(json.data, file_name));
    });
  };
}

export function fetchFile(file_name) {
  return (dispatch) => {
    return axios.get(`/file/${file_name}`).then((json) => {
      console.log(receiveJSON(json.data, file_name));
      dispatch(receiveJSON(json.data, file_name));
    });
  };
}

export function generateReport() {
  return (dispatch, getState) => {
    return axios
      .post("/generate_report", {
        annotations: getState().data.annotations,
        file_name: getState().data.file_name,
      })
      .then((json) => {
        var filename = json.data.success[1];
        dispatch(downloadReport(filename));
      });
  };
}

export function selectParam(name) {
  return {
    type: SELECT_PARAM,
    name: name,
  };
}

export function nextHeartbeat() {
  return {
    type: NEXT_HEARTBEAT,
  };
}

export function prevHeartbeat() {
  return {
    type: PREV_HEARTBEAT,
  };
}

export function addHeartbeat() {
  return {
    type: ADD_HEARTBEAT,
  };
}

export function removeHeartbeat() {
  return {
    type: REMOVE_HEARTBEAT,
  };
}

export function removeParam(name) {
  return {
    type: REMOVE_PARAMS,
    name: name,
  };
}

export function addMarkers(data) {
  return {
    type: ADD_ANNOTATIONS,
    paramName: data.paramName,
    data: data.data,
  };
}

export function changeUserInfo(field_name, value) {
  return {
    type: CHANGE_USER_INFO,
    field_name,
    value,
  };
}
