const params = (state = {}, action) => {
    switch (action.type) {
        case 'SELECT_PARAM':
            return {
                ...state,
                name: action.name
            };
        case 'REMOVE_PARAMS':
            return {
                ...state,
                name: ""
            };
        default:
            return state
    }
};

export default params