import axios from "axios";
import FileDownload from "react-file-download";
import { NotificationManager } from "../../components/common/react-notifications";
import {
  RECORDINGS_GET_LIST,
  RECORDINGS_GET_LIST_BY_ID,
  RECORDINGS_LIST_SUCCESS,
  RECORDINGS_GET_LIST_ERROR,
  RECORDINGS_GET_LIST_BY_ID_SUCCESS,
  RECORDINGS_GET_LIST_BY_ID_ERROR,
  RECORDINGS_GET_LIST_BY_USER_ID,
  RECORDINGS_GET_LIST_BY_USER_ID_SUCCESS,
  RECORDINGS_GET_LIST_BY_USER_ID_ERROR,
  RECORDINGS_GET_SEARCH_LIST_BY_TERM,
  RECORDINGS_GET_SEARCH_LIST_BY_TERM_SUCCESS,
  RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM,
  RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM_SUCCESS,
  RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM_ERROR,
  RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM,
  RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM_SUCCESS,
  RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM_ERROR,
  RECORDINGS_LIST_BY_ORDER,
  RECORDINGS_LIST_BY_ORDER_ERROR,
  RECORDINGS_LIST_BY_ORDER_SUCCESS,
  RECORDING_LIST_GET_LIST_WITH_FILTER,
  RECORDINGS_LIST_BY_ORDER_BY_CLIENT,
  RECORDINGS_LIST_BY_ORDER_BY_CLIENT_ERROR,
  RECORDINGS_LIST_BY_ORDER_BY_CLIENT_SUCCESS,
  RECORDINGS_LIST_BY_ORDER_BY_USER,
  RECORDINGS_LIST_BY_ORDER_BY_USER_SUCCESS,
  RECORDINGS_LIST_BY_ORDER_BY_USER_ERROR,
  RECORDINGS_GET_TAGS,
  RECORDINGS_GET_TAGS_SUCCESS,
  RECORDINGS_GET_TAGS_ERROR,
  RECEIVE_FILES,
  REQUEST_FILES,
  ADD_ANNOTATIONS,
  REMOVE_PARAMS,
  NEXT_HEARTBEAT,
  PREV_HEARTBEAT,
  ADD_HEARTBEAT,
  REMOVE_HEARTBEAT,
  CHANGE_USER_INFO,
  SEND_REQUEST_FILE,
  RECEIVE_JSON,
  SELECT_PARAM,
  RECORDINGS_GET_LIST_BY_DATES,
  RECORDINGS_GET_LIST_BY_DATES_SUCCESS,
  RECORDINGS_GET_LIST_BY_DATES_ERROR,
  RECORDINGS_GET_LIST_BY_DATES_COMPANY,
  RECORDINGS_GET_LIST_BY_DATES_COMPANY_SUCCESS,
  RECORDINGS_GET_LIST_BY_DATES_COMPANY_ERROR,
  RECORDINGS_GET_LIST_BY_DATES_USER,
  RECORDINGS_GET_LIST_BY_DATES_USER_SUCCESS,
  RECORDINGS_GET_LIST_BY_DATES_USER_ERROR,
  RECORDING_DELETE, ///* To delete the recording cts 14 sept */
  RECORDING_PAGINATION, // Add Pagination for new recording API 21 sep CTS
  SET_LOADER, // Add Pagination for new recording API 21 sep CTS
  RECORDING_ALL_USERS, // Add Pagination for list all users recordings by company id 26 sep 2023 CTS
} from "../actions";

const API_URL = process.env.REACT_APP_SERVER_URL;

export function receiveJSON(json, file_name) {
  return {
    type: RECEIVE_JSON,
    file_name,
    data: json,
  };
}

export const getRecordingsList = () => ({
  type: RECORDINGS_GET_LIST,
});

// export const getRecordingsList = (payload) => ({ // Pagination prms add by CTS 20 sep 2023
//   type: RECORDINGS_GET_LIST,
//   payload: payload,
// });

export const getRecordingsListByDates = (payload) => ({
  type: RECORDINGS_GET_LIST_BY_DATES,
  payload: payload,
});

export const getRecordingsListByDatesSuccess = (items) => ({
  type: RECORDINGS_GET_LIST_BY_DATES_SUCCESS,
  payload: items,
});

export const getRecordingsListByDatesError = (error) => ({
  type: RECORDINGS_GET_LIST_BY_DATES_ERROR,
  payload: error,
});

export const getRecordingsListByDatescompany = (payload) => ({
  type: RECORDINGS_GET_LIST_BY_DATES_COMPANY,
  payload: payload,
});

export const getRecordingsListByDatescompanysuccess = (items) => ({
  type: RECORDINGS_GET_LIST_BY_DATES_COMPANY_SUCCESS,
  payload: items,
});

export const getRecordingsListByDatescompanyerror = (error) => ({
  type: RECORDINGS_GET_LIST_BY_DATES_COMPANY_ERROR,
  payload: error,
});

export const getRecordingsListByDatesUser = (payload) => ({
  type: RECORDINGS_GET_LIST_BY_DATES_USER,
  payload: payload,
});

export const getRecordingsListByDatesUsersuccess = (items) => ({
  type: RECORDINGS_GET_LIST_BY_DATES_USER_SUCCESS,
  payload: items,
});

export const getRecordingsListByDatesUserError = (error) => ({
  type: RECORDINGS_GET_LIST_BY_DATES_USER_ERROR,
  payload: error,
});

export const getRecordingsTags = () => ({
  type: RECORDINGS_GET_TAGS,
});

export const getRecordingsTagsSuccess = (items) => ({
  type: RECORDINGS_GET_TAGS_SUCCESS,
  payload: items,
});

export const getRecordingsTagsError = (error) => ({
  type: RECORDINGS_GET_TAGS_ERROR,
  payload: error,
});

export const getRecordingsSearchListListByUserId = (payload) => ({
  type: RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM,
  payload: payload,
});

export const getRecordingsListFilter = (column, value) => ({
  type: RECORDING_LIST_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getRecordingsSearchListListByUserIdSuccess = (items) => ({
  type: RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM_SUCCESS,
  payload: items,
});

export const getRecordingsSearchListListByUserIdErrot = (items) => ({
  type: RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM_ERROR,
  payload: items,
});

export const getRecordedListWithOrder = (payload) => ({
  type: RECORDINGS_LIST_BY_ORDER,
  payload: payload,
});

export const getRecordedListWithOrderSuccess = (items) => ({
  type: RECORDINGS_LIST_BY_ORDER_SUCCESS,
  payload: items,
});

export const getRecordedListWithOrderError = (items) => ({
  type: RECORDINGS_LIST_BY_ORDER_ERROR,
  payload: items,
});
export const getRecordedListWithOrderbyClient = (payload) => ({
  type: RECORDINGS_LIST_BY_ORDER_BY_CLIENT,
  payload: payload,
});

export const getRecordedListWithOrderbyClientSuccess = (items) => ({
  type: RECORDINGS_LIST_BY_ORDER_BY_CLIENT_SUCCESS,
  payload: items,
});

export const getRecordedListWithOrderbyClientError = (items) => ({
  type: RECORDINGS_LIST_BY_ORDER_BY_CLIENT_ERROR,
  payload: items,
});

export const getRecordedListWithOrderbyUserId = (payload) => ({
  type: RECORDINGS_LIST_BY_ORDER_BY_USER,
  payload: payload,
});

export const getRecordedListWithOrderbyUserIdSuccess = (items) => ({
  type: RECORDINGS_LIST_BY_ORDER_BY_USER_SUCCESS,
  payload: items,
});

export const getRecordedListWithOrderbyUserIdError = (items) => ({
  type: RECORDINGS_LIST_BY_ORDER_BY_USER_ERROR,
  payload: items,
});

export const getRecordingsSearchList = (payload) => ({
  type: RECORDINGS_GET_SEARCH_LIST_BY_TERM,
  payload: payload,
});

export const getRecordingsSearchListListById = (payload) => ({
  type: RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM,
  payload: payload,
});

export const getRecordingsSearchListListByIdSuccess = (items) => ({
  type: RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM_SUCCESS,
  payload: items,
});

export const getRecordingsSearchListListByIdError = (items) => ({
  type: RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM_ERROR,
  payload: items,
});

export const getRecordingsSearchListTermSuccess = (items) => ({
  type: RECORDINGS_GET_SEARCH_LIST_BY_TERM_SUCCESS,
  payload: items,
});

export const getRecordingsListById = (id) => ({
  type: RECORDINGS_GET_LIST_BY_ID,
  payload: id,
});

export const getRecordingsListByUserId = (id) => ({
  type: RECORDINGS_GET_LIST_BY_USER_ID,
  payload: id,
});
// export const getRecordingsListByUserId = (id, page = 1) => ({
//   type: RECORDINGS_GET_LIST_BY_USER_ID,
//   payload: { id, page },
// });

export const getRecordingsListByIdSuccess = (items) => ({
  type: RECORDINGS_GET_LIST_BY_ID_SUCCESS,
  payload: items,
});

export const getRecordingsListByIdError = (error) => ({
  type: RECORDINGS_GET_LIST_BY_ID_ERROR,
  payload: error,
});

export const getRecordingsListByUserIdSuccess = (items) => ({
  type: RECORDINGS_GET_LIST_BY_USER_ID_SUCCESS,
  payload: items,
});

export const getRecordingsListByUserIdError = (error) => ({
  type: RECORDINGS_GET_LIST_BY_USER_ID_ERROR,
  payload: error,
});

export const getRecordingsListSuccess = (items) => ({
  type: RECORDINGS_LIST_SUCCESS,
  payload: items,
});

export const getRecordingsListError = (error) => ({
  type: RECORDINGS_GET_LIST_ERROR,
  payload: error,
});

/* To delete the recording cts 14 sept */

export const deleteRecording = (id) => ({
  type: RECORDING_DELETE,
  payload: id,
});

// Add Pagination for new recording API 21 sep CTS
export const recordingPagination = (data, pagetype) => ({
  type: RECORDING_PAGINATION,
  payload: data,
  page_type: pagetype,
});

// Add Pagination for new recording API 21 sep CTS
export const setLoader = () => ({
  type: SET_LOADER,
});

function requestFiles() {
  return {
    type: REQUEST_FILES,
  };
}

function receiveFiles(files) {
  return {
    type: RECEIVE_FILES,
    files,
    receivedAt: Date.now(),
  };
}

function fetchFiles() {
  return (dispatch) => {
    dispatch(requestFiles());
    return axios
      .get(`/files_list`)
      .then((response) => response)
      .then((json) => {
        dispatch(receiveFiles(json.data));
      });
  };
}

function shouldFetchFiles(state) {
  const files = state.files;
  if (!files) {
    return true;
  } else if (files.isFetching) {
    return false;
  } else {
    return true;
  }
}

export function sendFile(payload) {
  return (dispatch) => {
    document.getElementById("PreLoaderBar").style.display = "block";
    return axios.post(API_URL + `webupload`, payload).then((response) => {
      document.getElementById("PreLoaderBar").style.display = "none";

      NotificationManager.success(
        "Recording uploaded successfully",
        "File Upload Success",
        3000,
        null,
        null,
        ""
      );

      setTimeout(() => window.location.reload(false), 1000);
    });
  };
}

export function ReProcessFile(id) {
  return (dispatch) => {
    document.getElementById("PreLoaderBar").style.display = "block";

    return axios
      .post(API_URL + `reprocessfile`, {
        id: id,
      })
      .then((response) => {
        document.getElementById("PreLoaderBar").style.display = "none";
        window.location.reload(false);
      });
  };
}

export function sendData() {
  return (dispatch, getState) => {
    return axios.post(API_URL + `/save_file`, {
      annotations: getState().data.annotations,
      info: getState().data.info,
      file_name: getState().data.file_name,
    });
  };
}

export function fetchFilesIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchFiles(getState())) {
      return dispatch(fetchFiles());
    }
  };
}

export function fetchRecordingJson(file_name) {
  return (dispatch) => {
    return axios.get(API_URL + `fetchjson/${file_name}`).then((json) => {
      dispatch(receiveJSON(json.data, file_name));
    });
  };
}

export function fetchFile(file_name) {
  return (dispatch) => {
    return axios.get(`/file/${file_name}`).then((json) => {
      console.log(receiveJSON(json.data, file_name));

      dispatch(receiveJSON(json.data, file_name));
    });
  };
}

function downloadReport(downloadablefile) {
  document.getElementById("PreLoaderBar").style.display = "block";

  return (dispatch) => {
    return axios
      .get(API_URL + `download?file=` + downloadablefile, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileDownload(blob, "report.xlsx");

        document.getElementById("PreLoaderBar").style.display = "none";
      });
  };
}

// Batch Report Generation & Download
export function generateBatchReport(query, user_id, template_id, output_format = 'xlsx') {
  return (dispatch) => {
    // Show the preloader if it exists
    const preLoader = document.getElementById("PreLoaderBar");
    if (preLoader) {
      preLoader.style.display = "block";
    }

    return axios
      .post(`${API_URL}generate_batch_report`, {
        query: query,
        user_id: user_id,
        template_id: template_id,
        output_format: output_format, // Pass the desired format
      })
      .then((response) => {
        const id = response.data.id;
        dispatch(downloadBatchReportFile(id, output_format));
      })
      .catch((error) => {
        console.error("Error generating report:", error);
        // Hide the preloader in case of error
        if (preLoader) {
          preLoader.style.display = "none";
        }
        // Optionally, dispatch an error action or show a toast notification
      });
  };
}

// Action to download the batch report file based on ID and format
function downloadBatchReportFile(id, output_format) {
  return (dispatch) => {
    return axios
      .get(`${API_URL}downloadBatchReport`, {
        params: { id: id, output_format: output_format },
        responseType: "blob", // Important for handling binary data
      })
      .then((response) => {
        // Determine the file extension and MIME type based on format
        let fileExtension = 'xlsx';
        let mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (output_format === 'csv') {
          fileExtension = 'csv';
          mimeType = 'text/csv';
        }

        // Create a blob from the response data
        const blob = new Blob([response.data], { type: mimeType });

        // Generate a filename with the current date
        const date = new Date();
        const dateString = date.toISOString().split("T")[0];
        const filename = `Batch Report - ${dateString}.${fileExtension}`;

        // Trigger the file download
        FileDownload(blob, filename);

        // Hide the preloader if it exists
        const preLoader = document.getElementById("PreLoaderBar");
        if (preLoader) {
          preLoader.style.display = "none";
        }
      })
      .catch((error) => {
        console.error("Error downloading report:", error);
        // Hide the preloader in case of error
        const preLoader = document.getElementById("PreLoaderBar");
        if (preLoader) {
          preLoader.style.display = "none";
        }
        // Optionally, dispatch an error action or show a toast notification
      });
  };
}

export function downloadBatchReport(sql,userId) {
  return true;
} 

export function generateReport() {
  return (dispatch, getState) => {
    document.getElementById("PreLoaderBar").style.display = "block";

    return axios
      .post(API_URL + "generate_report", {
        annotations: getState().data.annotations,
        file_name: getState().data.file_name,
      })
      .then((json) => {
        var filename = getState().data.file_name;

        dispatch(downloadReport(filename));

        // console.log(dispatch(downloadReport(filename)));

        return;

        //console.log(filename);
        // var blob = new Blob([json.data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        //console.log(json.data.data);

        //return;

        //FileDownload(blob, filename);

        // dispatch(downloadReport(filename))
      });
  };
}

export function selectParam(name) {
  return {
    type: SELECT_PARAM,
    name: name,
  };
}

export function nextHeartbeat() {
  return {
    type: NEXT_HEARTBEAT,
  };
}

export function prevHeartbeat() {
  return {
    type: PREV_HEARTBEAT,
  };
}

export function addHeartbeat() {
  return {
    type: ADD_HEARTBEAT,
  };
}

export function removeHeartbeat() {
  return {
    type: REMOVE_HEARTBEAT,
  };
}

export function removeParam(name) {
  return {
    type: REMOVE_PARAMS,
    name: name,
  };
}

export function addMarkers(data) {
  return {
    type: ADD_ANNOTATIONS,
    paramName: data.paramName,
    data: data.data,
  };
}

export function changeUserInfo(field_name, value) {
  return {
    type: CHANGE_USER_INFO,
    field_name,
    value,
  };
}
