import {
    CLIENT_GET_LIST,
    CLIENT_LIST_SUCCESS,
    CLIENT_GET_LIST_ERROR,
    CLIENT_GET_LIST_SEARCH,
    CLIENT_ADD_ITEM,
    ADD_CLIENT_SUCCESS,
    ADD_CLIENT_ERROR,
    CLIENT_DELETE_ITEM,
    CLIENT_DELETE_SUCCESS,
    CLIENT_DELETE_ERROR,
    USER_ADD_ITEM,
    USER_ADD_SUCCESS,
    USER_ADD_ERROR,
    USER_DElETE_ITEM,
    USER_DELETE_SUCCESS,
    USER_DELETE_ERROR,
    USER_GET_LIST,
    USER_LIST_SUCCESS,
    USER_GET_LIST_ERROR,
    USER_GET_LIST_BY_COMPANY,
    USER_GET_LIST_BY_COMPANY_SUCCESS,
    USER_GET_LIST_BY_COMPANY_ERROR,
    CLIENT_GET_LIST_BY_ID,
    CLIENT_LIST_BY_ID_SUCCESS,
    CLIENT_LIST_BY_ID_ERROR,
    CLIENT_GET_SEARCH_LIST_BY_TERM,
    CLIENT_GET_SEARCH_LIST_BY_TERM_SUCCESS,
    CLIENT_GET_SEARCH_LIST_BY_TERM_ERROR,
    CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM,
    CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM_SUCCESS,
    CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM_ERROR,
    CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM,
    CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM_SUCCESS,
    CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM_ERROR,
    ORG_GET_SEARCH_LIST_BY_TERM,
    ORG_GET_SEARCH_LIST_BY_TERM_SUCCESS,
    ORG_GET_SEARCH_LIST_BY_TERM_ERROR,
    USER_GET_LIST_PAGINATION, // Add user listing pagination on 26 sep 2023 by cts


} from '../actions';



/* search  functions*/




export const getClientSearchList =(query) =>({

    type: CLIENT_GET_SEARCH_LIST_BY_TERM,
    payload: query

});

export const getOrgSearchListSuccess =(items) =>({

    type: ORG_GET_SEARCH_LIST_BY_TERM_SUCCESS,
    payload: items

});



export const getOrgSearchListError =(items) =>({

    type: ORG_GET_SEARCH_LIST_BY_TERM_ERROR,
    payload: items

});
export const getOrgSearchList = (query) =>({
    
    type: ORG_GET_SEARCH_LIST_BY_TERM,
    payload: query

})

export const getRecordingsSearchListTermSuccess =(items) =>({

    type: CLIENT_GET_SEARCH_LIST_BY_TERM_SUCCESS,
    payload: items

});
export const getRecordingsSearchListTermError =(items) =>({

    type: CLIENT_GET_SEARCH_LIST_BY_TERM_ERROR,
    payload: items

});

export const getClientSearchListListById = (payload) =>({

    type: CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM,
    payload: payload
   
});

export const getClientSearchListListByIdSuccess = (items) =>({

    type: CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM_SUCCESS,
    payload: items

});


export const getClientSearchListListByIdError = (items) =>({

    type: CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM_ERROR,
    payload: items

});

export const getClientSearchListListByUserId = (payload) =>({

type: CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM,
    payload: payload

})


export const getClientSearchListListByUserIdSuccess = (items) =>({

    type: CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM_SUCCESS,
    payload: items


})

export const getClientSearchListListByUserIdError = (items) =>({

    type: CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM_ERROR,
    payload: items

})



/* search  functions ends */

export const getClientList = () => ({
    type: CLIENT_GET_LIST
});


export const getClientListById = (id) => ({
    type: CLIENT_GET_LIST_BY_ID,
    payload: id
});

export const getClientListSuccess = (items) => ({
    type: CLIENT_LIST_SUCCESS,
    payload: items
});

export const getClientbyIdListSuccess = (items) => ({
    type: CLIENT_LIST_BY_ID_SUCCESS,
    payload: items
});


export const getClientListbyIdError = (error) => ({
    type: CLIENT_LIST_BY_ID_ERROR,
    payload: error
});

export const getClientListError = (error) => ({
    type: CLIENT_GET_LIST_ERROR,
    payload: error
});

export const getClientListSearch = (keyword) => ({
    type: CLIENT_GET_LIST_SEARCH,
    payload: keyword
});

export const addClientItem = (items) => ({

    type: CLIENT_ADD_ITEM,
    payload: items
});

export const deleteClientItem =(item) =>({

    type: CLIENT_DELETE_ITEM,
    payload: item
});

export const addClientSuccess = (items) => ({
    type: ADD_CLIENT_SUCCESS,
    payload: items
});

export const addClientError = (error) => ({
    type: ADD_CLIENT_ERROR,
    payload: error
});


export const deleteClientSuccess = (items) => ({
    type: CLIENT_DELETE_SUCCESS,
    payload: items
});

export const deleteClientError = (error) => ({
    type: CLIENT_DELETE_ERROR,
    payload: error
});

export const addUserItem = (item) => ({
    type: USER_ADD_ITEM,
    payload: item
});


export const addUserError = (error) => ({
    type: USER_ADD_SUCCESS,
    payload: error
});


export const addUserSuccess = (items) => ({
    type: USER_ADD_ERROR,
    payload: items
});


export const getUsersList = () => ({
    type: USER_GET_LIST,    
});

// Add user listing pagination on 26 sep 2023 by cts
export const getUsersListPagination = (data, pagetype) => ({
    type: USER_GET_LIST_PAGINATION,
    payload: data,
    page_type:pagetype,
  });

export const getUsersListbycompanyId = (id) =>({

    type: USER_GET_LIST_BY_COMPANY,
    payload: id,
});
 
export const deleteUserItem =(item) =>({

    type: USER_DElETE_ITEM,
    payload: item
});
 


export const getUsersListSuccess = (items) => ({
    type: USER_LIST_SUCCESS,
    payload: items
});

export const getUsersListError = (error) => ({
    type: USER_GET_LIST_ERROR,
    payload: error
});

export const getUsersListbycompanySuccess = (items) => ({
    type: USER_GET_LIST_BY_COMPANY_SUCCESS,
    payload: items
});

export const getUsersListbyCompanyError = (error) => ({
    type: USER_GET_LIST_BY_COMPANY_ERROR,
    payload: error
});

