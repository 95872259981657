import {
	CLIENT_GET_LIST,
    CLIENT_LIST_SUCCESS,
    CLIENT_GET_LIST_ERROR,
    CLIENT_GET_LIST_SEARCH,
    CLIENT_ADD_ITEM,
    ADD_CLIENT_SUCCESS,
    ADD_CLIENT_ERROR,
    CLIENT_DELETE_ITEM,
    USER_ADD_ITEM,
    USER_ADD_SUCCESS,
    USER_ADD_ERROR,
    USER_DElETE_ITEM,
    USER_DELETE_SUCCESS,
    USER_DELETE_ERROR,
    USER_GET_LIST,
    USER_LIST_SUCCESS,
	USER_GET_LIST_ERROR,
	CLIENT_GET_LIST_BY_ID,
	GET_CLIENT_LIST_BY_ID,
    CLIENT_LIST_BY_ID_SUCCESS,
    CLIENT_LIST_BY_ID_ERROR,
    CLIENT_GET_SEARCH_LIST_BY_TERM,
    CLIENT_GET_SEARCH_LIST_BY_TERM_SUCCESS,
    CLIENT_GET_SEARCH_LIST_BY_TERM_ERROR,
    CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM,
    CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM_SUCCESS,
    CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM_ERROR,
    CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM,
    CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM_SUCCESS,
    CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM_ERROR,
    USER_GET_LIST_BY_COMPANY,
    USER_GET_LIST_BY_COMPANY_SUCCESS,
    USER_GET_LIST_BY_COMPANY_ERROR,
    ORG_GET_SEARCH_LIST_BY_TERM_SUCCESS,
	ORG_GET_SEARCH_LIST_BY_TERM_ERROR,
	ORG_GET_SEARCH_LIST_BY_TERM,
	USER_GET_LIST_PAGINATION, // Add user listing pagination on 26 sep2023 by cts

} from '../actions';

const INIT_STATE = {
	
	allClients: [],
	clientItems: [],
	error: '',
	filter: '',
	searchKeyword: '',
	orderColumn: null,
	loading: false,
	success:'',
	allUsers:[],
	usersItems:[],
	getclientsbyid:"",

	labels: [
		{ label: "TITLE", color: "secondary" },
		
	],
	orderColumns: [
		{ column: "title", label: "Title" },
		
	],
	
	
};

export default (state = INIT_STATE, action) => {
	
	switch (action.type) {

		case CLIENT_GET_LIST:
			return { ...state, loading: false };

		case CLIENT_LIST_SUCCESS:
			return { ...state, loading: true, allClients: action.payload.data.data, clientItems: action.payload.data.data ,allUsers: action.payload.data.data, usersItems: action.payload.data.data, hdfjsfh: action.payload.data.data};

		case CLIENT_GET_LIST_ERROR:
			return { ...state, loading: false, error: action.payload };



		case ORG_GET_SEARCH_LIST_BY_TERM:
			return { ...state, loading: false };

		case ORG_GET_SEARCH_LIST_BY_TERM_SUCCESS:
			return { ...state, loading: true, allClients: action.payload.data.data, clientItems: action.payload.data.data ,allUsers: action.payload.data.data, usersItems: action.payload.data.data, hdfjsfh: action.payload.data.data};

		case ORG_GET_SEARCH_LIST_BY_TERM_ERROR:
			return { ...state, loading: false, error: action.payload };


		case CLIENT_GET_LIST_SEARCH:
			if (action.payload === '') {
				return { ...state, clientItems: state.allClients };
			} else {
				const keyword = action.payload.toLowerCase();
				const searchItems = state.allClients.filter((item) =>
					item.title.toLowerCase().indexOf(keyword) > -1  || item.status.toLowerCase().indexOf(keyword) > -1 );
				return { ...state, loading: true, clientItems: searchItems, searchKeyword: action.payload }
			}

		case CLIENT_ADD_ITEM:
			return { ...state, loading: false };

		case ADD_CLIENT_SUCCESS:
			//return { ...state, loading: true, allClients: action.payload, todoItems: action.payload };
			 return { ...state, loading: true, success: action.payload };
		case ADD_CLIENT_ERROR:
			return { ...state, loading: true, error: action.payload };

		case CLIENT_DELETE_ITEM:
			return { ...state, loading: true };


		case USER_ADD_ITEM:
			return { ...state, loading: false };

		case USER_ADD_SUCCESS:
			//return { ...state, loading: true, allClients: action.payload, todoItems: action.payload };
			 return { ...state, loading: true, success: action.payload };
		case USER_ADD_ERROR:
			return { ...state, loading: false, error: action.payload };

		case USER_GET_LIST:
		return { ...state, loading: false };
		
		// Add user list pagination ion 26 sep 2023 by cts
		case USER_GET_LIST_PAGINATION:
			let all_users = [];
			if(action.page_type == 'default') {
				all_users = [...action.payload];
			} else {
				all_users = [...state.allUsers,...action.payload];
			}
			
			return {...state, loading: true, allUsers: all_users}		

		case USER_LIST_SUCCESS:
			return { ...state, loading: true, allUsers: action.payload.data.data, usersItems: action.payload.data.data };

		case USER_GET_LIST_ERROR:
			return { ...state, loading: true, error: action.payload };

		case USER_GET_LIST_BY_COMPANY:


		return { ...state, loading: false };

		case USER_GET_LIST_BY_COMPANY_SUCCESS:
			return { ...state, loading: true, allUsers: action.payload.data.data, usersItems: action.payload.data.data };

		case USER_GET_LIST_BY_COMPANY_ERROR:
			return { ...state, loading: true, error: action.payload };

		case USER_DElETE_ITEM:
			return { ...state, loading: true };

	    case CLIENT_GET_LIST_BY_ID:
			return { ...state, loading: false  };

		case CLIENT_LIST_BY_ID_SUCCESS:
			return { ...state, loading: true , allUsers: action.payload.data.data, usersItems: action.payload.data.data};

		case CLIENT_LIST_BY_ID_ERROR:
			return { ...state, loading: false , error: action.payload };
		
		case CLIENT_GET_SEARCH_LIST_BY_TERM:
			return { ...state, loading: false};

		case CLIENT_GET_SEARCH_LIST_BY_TERM_SUCCESS:
			return { ...state, loading: true, allUsers: action.payload.data.data , usersItems: action.payload.data.data};
		
		case CLIENT_GET_SEARCH_LIST_BY_TERM_ERROR:
				return { ...state, loading: false, error: action.payload };

		case CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM:
    			return {...state, loading:false};

	    case CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM_SUCCESS:
				return { ...state, loading: true, allUsers: action.payload.data.data , usersItems: action.payload.data.data};
		case CLIENT_GET_SEARCH_LIST_BY_ID_AND_TERM_ERROR:
				return { ...state, loading: false, error: action.payload };
 
		case CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM:
    			return {...state, loading:false};

	    case CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM_SUCCESS:
				return { ...state, loading: true, allUsers: action.payload.data.data , usersItems: action.payload.data.data};
		case CLIENT_GET_SEARCH_LIST_BY_USERID_AND_TERM_ERROR:
				return { ...state, loading: false, error: action.payload };



		default: return { ...state };
	}
}

