import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getDateWithFormat } from "../../helpers/Utils";

import {  RECORDINGS_GET_LIST,RECORDINGS_GET_LIST_BY_ID,RECORDINGS_LIST_SUCCESS,RECORDINGS_GET_LIST_ERROR , RECORDINGS_GET_LIST_BY_ID_SUCCESS,
    RECORDINGS_GET_LIST_BY_ID_ERROR,
    RECORDINGS_GET_LIST_BY_USER_ID,
    RECORDINGS_GET_LIST_BY_USER_ID_SUCCESS,
    RECORDINGS_GET_LIST_BY_USER_ID_ERROR,
    RECORDINGS_GET_SEARCH_LIST_BY_TERM,
    RECORDINGS_GET_SEARCH_LIST_BY_TERM_SUCCESS,
    RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM,
    RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM_SUCCESS,
    RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM_ERROR,
    RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM,
    RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM_SUCCESS,
    RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM_ERROR,
    RECORDINGS_LIST_BY_ORDER,
    RECORDINGS_LIST_BY_ORDER_ERROR,
    RECORDINGS_LIST_BY_ORDER_SUCCESS,
    RECORDINGS_LIST_BY_ORDER_BY_CLIENT,
    RECORDINGS_LIST_BY_ORDER_BY_CLIENT_SUCCESS,
    RECORDINGS_LIST_BY_ORDER_BY_CLIENT_ERROR,
    RECORDINGS_LIST_BY_ORDER_BY_USER,
    RECORDINGS_LIST_BY_ORDER_BY_USER_SUCCESS,
    RECORDINGS_LIST_BY_ORDER_BY_USER_ERROR,
    RECORDINGS_GET_TAGS,
    RECORDINGS_GET_TAGS_SUCCESS,
    RECORDINGS_GET_TAGS_ERROR,
    RECORDINGS_GET_LIST_BY_DATES,
    RECORDINGS_GET_LIST_BY_DATES_SUCCESS,
    RECORDINGS_GET_LIST_BY_DATES_ERROR,
    RECORDINGS_GET_LIST_BY_DATES_COMPANY,
    RECORDINGS_GET_LIST_BY_DATES_COMPANY_SUCCESS,
    RECORDINGS_GET_LIST_BY_DATES_COMPANY_ERROR,
    RECORDINGS_GET_LIST_BY_DATES_USER,
    RECORDINGS_GET_LIST_BY_DATES_USER_SUCCESS,
    RECORDINGS_GET_LIST_BY_DATES_USER_ERROR,

} from "../actions";



import  Dataservice  from '../../helpers/Dataservice';
import todoData from "../../data/todos.json";
import {
  getRecordingsList,
  getRecordingsListById,
  getRecordingsListSuccess,
  getRecordingsListError,
  getRecordingsListByIdSuccess,
  getRecordingsListByIdError,
  getRecordingsListByUserIdSuccess,
  getRecordingsListByUserIdError,
  getRecordingsSearchListTermSuccess,
  getRecordingsSearchList,
  getRecordingsSearchListListById,
  getRecordingsSearchListListByIdSuccess,
  getRecordingsSearchListListByIdError,
  getRecordingsSearchListListByUserIdSuccess,
  getRecordingsSearchListListByUserIdErrot,
  getRecordingsSearchListListByUserId,
  getRecordedListWithOrder,
  getRecordedListWithOrderSuccess,
  getRecordedListWithOrderError,
  getRecordedListWithOrderbyClient,
  getRecordedListWithOrderbyClientSuccess,
  getRecordedListWithOrderbyClientError,
  getRecordedListWithOrderbyUserId,
  getRecordedListWithOrderbyUserIdSuccess,
  getRecordedListWithOrderbyUserIdError,
  getRecordingsTags,
  getRecordingsTagsSuccess,
  getRecordingsTagsError,
  getRecordingsListByDates,
  getRecordingsListByDatesSuccess,
  getRecordingsListByDatesError,
  getRecordingsListByDatescompany,
  getRecordingsListByDatescompanysuccess,
  getRecordingsListByDatescompanyerror,
  getRecordingsListByDatesUser,
  getRecordingsListByDatesUsersuccess,
  getRecordingsListByDatesUserError,


  
} from "./actions";

/************************ */ // Pagination function added  20 Sep 2023 CTS
// const getRecordingsListRequest = async (payload) => 
//   Dataservice.getRecordingsList(payload)
//         .then(response => response)
//         .catch(error => error);

//     function* getRecordingsListsaga({payload}) {
//       try {

//         const response = yield call(getRecordingsListRequest, payload);

//         yield put(getRecordingsListSuccess(response));
//       } catch (error) {
//         yield put(getRecordingsListError(error));
//       }
//     }
/************************ */


const getRecordingsListRequest = async () => 
  Dataservice.getRecordingsList()
        .then(response => response)
        .catch(error => error);

function* getRecordingsListsaga() {
  try {
    const response = yield call(getRecordingsListRequest);

    yield put(getRecordingsListSuccess(response));
  } catch (error) {
    yield put(getRecordingsListError(error));
  }
}

const getRecordingsListbydatessaga_callback = async (payload) => 
  Dataservice.getRecordingsListbydates(payload)
        .then(response => response)
        .catch(error => error);
  function* getRecordingsListbydatessaga({payload}) {
    try
    {
      const response = yield call(getRecordingsListbydatessaga_callback, payload);
      yield put(getRecordingsListByDatesSuccess(response));
    } catch (error) {
      yield put(getRecordingsListByDatesError(error));
    }
  }


const getRecordingsListbydatesCompanysaga_callback = async (payload) => 
  Dataservice.getRecordingsListbydatesCompany(payload)
        .then(response => response)
        .catch(error => error);

  function* getRecordingsListbydatesCompanysaga({payload}) {
    try {
      const response = yield call(getRecordingsListbydatesCompanysaga_callback, payload);
      yield put(getRecordingsListByDatescompanysuccess(response));
    } catch (error) {
      yield put(getRecordingsListByDatescompanyerror(error));
    }
  }

const getRecordingsListbydateUserssaga_callback = async (payload) => 
  Dataservice.getRecordingsListbydatesUser(payload)
        .then(response => response)
        .catch(error => error);

  function* getRecordingsListbydateUserssaga({payload}) {
    try {

      const response = yield call(getRecordingsListbydateUserssaga_callback, payload);

      yield put(getRecordingsListByDatesUsersuccess(response));
    } catch (error) {
      yield put(getRecordingsListByDatesUserError(error));
    }
  }

const getRecordingsListRequestbyCompanyId = async (payload) => 
  Dataservice.getRecordingsListbyCompanyId(payload)
        .then(response => response)
        .catch(error => error);

    function* getRecordingsListbyId({payload}) {
      try {

        const response = yield call(getRecordingsListRequestbyCompanyId, payload);

        yield put(getRecordingsListByIdSuccess(response));
      } catch (error) {
        yield put(getRecordingsListByIdError(error));
      }
    }


    const getRecordingsListRequestbyUserId = async (payload) =>
      Dataservice.getRecordingsListbyUserid(payload)
        .then((response) => response)
        .catch((error) => error);

    function* getRecordingsListbyUserId({payload}) {
      try {

        const response = yield call(getRecordingsListRequestbyUserId, payload);
        yield put(getRecordingsListByUserIdSuccess(response));
      } catch (error) {
        yield put(getRecordingsListByUserIdError(error));
      }
    }



const getRecordingssearchListterm = async(payload)=>

  Dataservice.getRecordingssearchListterm(payload)
        .then(response => response)
        .catch(error => error);

function* getRecordingsListSearchbyTerm({payload}) {
  try 
  {

    const response = yield call(getRecordingssearchListterm, payload);
    yield put(getRecordingsSearchListTermSuccess(response));
  
   }catch (error) {
      console.log(error);

  }
}


const getRecordingsListbyIdandTerm_callback = async(payload)=>

  Dataservice.getRecordingssearchListtermandId(payload)
        .then(response => response)
        .catch(error => error);


function* getRecordingsListbyIdandTerm({payload}){

  try{

      const response = yield call(getRecordingsListbyIdandTerm_callback, payload);
      yield put(getRecordingsSearchListListByIdSuccess(response));
    } catch (error) {
      yield put(getRecordingsSearchListListByIdError(error));

  }

}


const getRecordingsListbyOrder_callback = async(payload)=>

  Dataservice.getRecordingsListByOrderFetch(payload)
        .then(response => response)
        .catch(error => error);


function* getRecordingsListbyOrder({payload}){

  try {
   
        const response = yield call(getRecordingsListbyOrder_callback, payload);
        yield put(getRecordingsSearchListListByIdSuccess(response));
      } catch (error) {
        yield put(getRecordedListWithOrderError(error));

    }

}


const getRecordingsListbyUSERId_callback = async (payload) =>
  Dataservice.getRecordingssearchListuserid(payload)
    .then((response) => response)
    .catch((error) => error);
// const getRecordingsListbyUSERId_callback = async ({ id, page = 1 }) =>
//   Dataservice.getRecordingssearchListuserid({ id, page })
//     .then((response) => response)
//     .catch((error) => error);

function* getRecordingsListbyUSERId({ payload }) {
  try {
    const response = yield call(getRecordingsListbyUSERId_callback, payload);
    yield put(getRecordedListWithOrderSuccess(response));
  } catch (error) {
    yield put(getRecordingsSearchListListByUserIdErrot(error));
  }
}


const getRecordingsListbyClientOrder_callback = async(payload)=>

  Dataservice.getRecordingsListbyClientOrder(payload)
        .then(response => response)
        .catch(error => error);


function* getRecordingsListbyClientOrder({payload}){

try {
    const response = yield call(getRecordingsListbyClientOrder_callback, payload);
    yield put(getRecordedListWithOrderbyClientSuccess(response));
  } catch (error) {
      yield put(getRecordedListWithOrderbyClientError(error));

  }

}


const getRecordingsListbyUserOrder_callback = async(payload)=>

  Dataservice.getRecordingsListbyUserOrder(payload)
        .then(response => response)
        .catch(error => error);


function* getRecordingsListbyUserOrder({payload}){

  try {
      const response = yield call(getRecordingsListbyUserOrder_callback, payload);
      yield put(getRecordedListWithOrderbyUserIdSuccess(response));
    } catch (error) {
        yield put(getRecordedListWithOrderbyUserIdError(error));

  }


}

const getRecordingsTags_callback = async()=>

  Dataservice.getRecordingsTagsP()
        .then(response => response)
        .catch(error => error);


function* getRecordingsTagsAll(){

  try {
      const response = yield call(getRecordingsTags_callback);
      yield put(getRecordingsTagsSuccess(response));
    } catch (error) {

      yield put(getRecordingsTagsError(error));

  }

}

export function* watchGetList() {
  yield takeEvery(RECORDINGS_GET_LIST, getRecordingsListsaga);
}


export function* watchGetListbyDates() {
  yield takeEvery(RECORDINGS_GET_LIST_BY_DATES, getRecordingsListbydatessaga);
}

export function* watchGetListbyDatesCompany() {
  yield takeEvery(RECORDINGS_GET_LIST_BY_DATES_COMPANY, getRecordingsListbydatesCompanysaga);
}


export function* watchGetListbyDatesUser() {
  yield takeEvery(RECORDINGS_GET_LIST_BY_DATES_USER, getRecordingsListbydateUserssaga);
}


export function* watchGetListbyUserId() {
  yield takeEvery(RECORDINGS_GET_LIST_BY_USER_ID, getRecordingsListbyUserId);
}

export function* watchGetListSearchbyterm() {
  yield takeEvery(RECORDINGS_GET_SEARCH_LIST_BY_TERM, getRecordingsListSearchbyTerm);
}


export function* watchGetListbyClientId() {
  yield takeEvery(RECORDINGS_GET_LIST_BY_ID, getRecordingsListbyId);
}

export function* watchGetListSearchbytermandId() {
  yield takeEvery(RECORDINGS_GET_SEARCH_LIST_BY_ID_AND_TERM, getRecordingsListbyIdandTerm);
}
 
export function* watchGetListSearchbyuserIDDD() {
  yield takeEvery(RECORDINGS_GET_SEARCH_LIST_BY_USERID_AND_TERM, getRecordingsListbyUSERId);
}

  
export function* watchGetRecordingListOrder() {
  yield takeEvery(RECORDINGS_LIST_BY_ORDER, getRecordingsListbyOrder);
}

export function* watchGetRecordingListOrderbyClient() {
  yield takeEvery(RECORDINGS_LIST_BY_ORDER_BY_CLIENT, getRecordingsListbyClientOrder);
}

export function* watchGetRecordingListOrderbyUser() {
  yield takeEvery(RECORDINGS_LIST_BY_ORDER_BY_USER, getRecordingsListbyUserOrder);
}
export function* watchGetTags() {
  yield takeEvery(RECORDINGS_GET_TAGS, getRecordingsTagsAll);
}
export default function* rootSaga() {
  yield all([fork(watchGetList), fork(watchGetListbyUserId), fork(watchGetListbyClientId), fork(watchGetListSearchbyterm), fork(watchGetListSearchbytermandId),  fork(watchGetListSearchbyuserIDDD), fork(watchGetRecordingListOrder), fork(watchGetRecordingListOrderbyClient), fork(watchGetRecordingListOrderbyUser),fork(watchGetTags), fork(watchGetListbyDates), fork(watchGetListbyDatesCompany), fork(watchGetListbyDatesUser) ]);
}
